import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Image from "gatsby-image";

import { rhythm, scale } from "../utils/typography";

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;
    let header;

    if (location.pathname === rootPath) {
      header = (
        <StaticQuery
          query={avatarQuery}
          render={(data) => {
            return (
              <h1
                style={{
                  ...scale(1.5),
                  marginBottom: rhythm(1.5),
                  marginTop: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Image
                  fixed={data.avatar.childImageSharp.fixed}
                  alt={""}
                  style={{
                    marginRight: rhythm(2 / 3),
                    marginBottom: 0,
                    minWidth: 50,
                    borderRadius: "100%",
                  }}
                />
                <Link
                  style={{
                    boxShadow: "none",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                  to={"/"}
                >
                  {title}
                </Link>
              </h1>
            );
          }}
        />
      );
    } else {
      header = (
        <h3
          style={{
            fontFamily: "Montserrat, sans-serif",
            marginTop: 0,
            marginBottom: rhythm(-1),
          }}
        >
          <Link
            style={{
              boxShadow: "none",
              textDecoration: "none",
              color: "inherit",
            }}
            to={"/"}
          >
            {title}
          </Link>
        </h3>
      );
    }
    return (
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        {header}
        {children}
      </div>
    );
  }
}

const avatarQuery = graphql`
  query AvatarQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
      childImageSharp {
        fixed(width: 69, height: 69) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

export default Layout;
