import Typography from 'typography'
import sutro from 'typography-theme-sutro'

sutro.overrideThemeStyles = () => ({
  'a': {
    color: '#6772e5'
  }
})

// TODO: modify line height and font sizes
// TODO: See if I need to delete this googleFonts Line

// delete Wordpress2016.googleFonts

const typography = new Typography(sutro)

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
